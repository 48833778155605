@import "./css/variables.scss";

* {
  box-sizing: border-box;
}

body {
  background-color: $bg-primary;
}

/* Desktop styles */
@media (min-width: $desktop-width) and (min-height: $desktop-height) {
  body {
    background-image: url("./img/bg.jpg");
    overflow: hidden;
  }

  .app {
    @include flex-center;
  }
}
