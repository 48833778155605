@import "./variables.scss";

.terminal {
  @include code;
  color: $text-primary;
  word-wrap: break-word;
  min-height: 100%;
}

.line {
  margin-bottom: 15px;

  &.help > .suggestion {
    margin: 0 !important;
  }
}

.input-info {
  float: left;
}

.input-container {
  display: block;
  overflow: hidden;
}

input {
  @include code;
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  width: 100%;
  color: $text-secondary;
}

.suggestions {
  min-height: 1em;
  padding: 0;

  > .suggestion {
    margin-right: 16px;
  }
}

.text-accent {
  color: $text-accent;
  font-weight: bold;
}

.text-secondary {
  color: $text-secondary;
}

.mobile-hidden {
  display: none;
}

/* Desktop styles */
@media (min-width: $desktop-width) and (min-height: $desktop-height) {
  .terminal {
    max-height: 100%;
    overflow: auto;
  }

  .mobile-hidden {
    display: initial;
  }

  .mobile-only {
    display: none;
  }
}
