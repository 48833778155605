$animation-duration: 1s;

// Desktop breakpoints
$desktop-width: 768px;
$desktop-height: 600px;

// Component colors
$dv-fill: #222222;
$dv-border: lighten($dv-fill, 25%);
$sc-fill: #000000;
$kb-fill: #cccccc;
$kb-border: darken($kb-fill, 30%);

// Theme colors
$bg-primary: #2e333a;
$bg-secondary: rgba(0,10,50,0.5);
$text-primary: #cccccc;
$text-secondary: #ffc19e;
$text-accent: #d37e4e;
$text-title: #000000;
$link: #4fadff;

@mixin code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  font-size: 1em;
}

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin fadein-keyframe($name, $yFrom, $yTo, $xFrom: 0px, $xTo: 0px) {
  @keyframes #{$name} {
    from {
      opacity: 0;
      transform: translateY($yFrom) translateX($xFrom);
    }
    to {
      opacity: 1;
      transform: translateY($yTo) translateX($xTo);
    }
  }
}
