@import "./variables.scss";

.title {
  display: none;
}

.screen {
  padding: 10px;
  height: 100vh;
}

/* Desktop styles */
@media (min-width: $desktop-width) and (min-height: $desktop-height) {
  .container {
    @include flex-center;
    flex-direction: column;
    height: 100vh;
    animation: fadein-device $animation-duration;
  }

  .device {
    background-color: $dv-fill;
    padding: 15px;
    border: 3px solid $dv-border;
    border-bottom: none;
    border-radius: 8px 8px 0px 0px;
    height: 50vmin;
    width: 75vmin;
  }

  .screen {
    background-color: $sc-fill;
    height: 100%;
  }

  .keyboard {
    display: flex;
    justify-content: center;
    background-color: $kb-fill;
    border: 2px solid $kb-border;
    border-radius: 4px 4px 16px 16px;
    height: 3vmin;
    width: 98vmin;
  }

  .keyboard-grip {
    background-color: $kb-border;
    border-radius: 0px 0px 8px 8px;
    height: 40%;
    width: 25%;
  }

  @include fadein-keyframe(fadein-device, -10vmin, 0px);
}
