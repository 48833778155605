@import "./variables.scss";

.me {
  text-align: center;
  border-top: 5px dotted $text-primary;
  border-bottom: 5px dotted $text-primary;
  padding: 10% 10px;
  line-height: 1.5;
}

.me > h1 {
  font-size: 2rem;
  font-weight: normal;
  margin-top: 0;
}

a,
a:visited {
  color: $link;
}

hr {
  border-color: $dv-fill;
  border-style: dashed;
}
