@import "./variables.scss";

.title {
  display: none;
}

/* Desktop styles */
@media (min-width: $desktop-width) and (min-height: $desktop-height) {
  .bg {
    position: absolute;
    padding: 5vmin;
    z-index: -1;
  }

  .title {
    display: block;
    position: absolute;
    margin: 0;
    z-index: 1;
    color: $text-title;
    font-size: 5vmin;
    font-weight: normal;
    letter-spacing: 0.1em;
    animation: fadein-title $animation-duration;
  }

  .shape {
    background-color: $bg-secondary;
    height: 90vmin;
    width: 90vmin;
    border-radius: 50%;
    animation: fadein-circle $animation-duration;
  }

  @include fadein-keyframe(fadein-title, 0px, 0px, -10vmin, 0px);
  @include fadein-keyframe(fadein-circle, 10vmin, 0px);
}
